import "antd/dist/antd.compact.css";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import StylesProvider from "@atlas/uicorn/lib/System/StylesProvider";

function HelpCenterApp({ Component, pageProps }: AppProps) {
  return (
    <StylesProvider>
      <Component {...pageProps} />
    </StylesProvider>
  );
}

export default HelpCenterApp;
