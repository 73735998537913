import window from '@atlas/tool/box/browser/window';
import { useCallback, useLayoutEffect, useState } from 'react';

/**
 * Suggested options:
 * - compact: < 1024px ~ tablet in landscape mode – display a single column, panes activate by buttons
 * - narrow: < 1280px ~ laptop/budget monitor - display two columns, left pane is visible, right pane activates by button
 * - wide: > 1280px ~ normal desktop monitor - display three columns, both panes are visible
 *
 * Currently we have info pane with fixed in useSidePaneWidth that may be too wide for 1280 display.
 * Thus, breakpoint is moved to the next laptop width of 1366px.
 */

export const SCREEN_VARIANTS = {
    tiny: '(max-width: 620px)',
    mobile: '(max-width: 700px)',
    compact: '(max-width: 1023px)',
    // will work after updating info pane to be responsive and slightly narrower
    // narrow: '(min-width: 1024px and max-width: 1279px)',
    // wide: '(min-width: 1280px)',
    narrow: '(min-width: 1024px and max-width: 1365px)',
    wide: '(min-width: 1366px)',
};

export type TScreenVariant = keyof typeof SCREEN_VARIANTS;

export default function useMatchScreenVariant(variant: TScreenVariant) {
    const [response, setResponse] = useState(!!window?.matchMedia(SCREEN_VARIANTS[variant]).matches);

    const handleResize = useCallback(
        () => setResponse(!!window?.matchMedia(SCREEN_VARIANTS[variant]).matches),
        [variant],
    );

    useLayoutEffect(() => {
        window?.addEventListener('resize', handleResize);

        return () => window?.removeEventListener('resize', handleResize);
    });

    return response;
}

// #region Tests
if (import.meta.vitest) {
    const { describe, it } = import.meta.vitest;

    describe.todo('useMatchScreenVariant', () => {
        it('does the job on resize', () => {});
    });
}

// #endregion Tests
