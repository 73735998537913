// eslint-disable-next-line no-restricted-globals
export default typeof window !== 'undefined' ? window : undefined;

// #region Tests

if (import.meta.vitest) {
    const { describe, it } = import.meta.vitest;
    describe('window', () => {
        it('should do nothing but return the global variable', () => {});
    });
}

// #endregion
